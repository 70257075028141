<template>
  <div>
    <b-list-group flush>
      {{ $t("pages.physicalObjects.notes") }}
      <b-list-group-item
        v-for="(item, index) in content"
        :key="'notes-' + index"
      >
        <b-row>
          <b-col>
            <b-form-input
              :id="'notes-input-' + index"
              v-model="item['notes']"
              @change="changed"
              @input="changed"
            />
          </b-col>
          <b-col sm="auto">
            <multiselect
              v-if="languages.length > 0"
              v-model="item.lang.id"
              :options="languages.map(i => i.id)"
              :custom-label="key => languages.find(x => x.id == key).sv"
              :show-labels="false"
              @select="changed"
            />
          </b-col>
        </b-row>
        <b-button
          v-t="'pages.physicalObjects.notesdelete'"
          @click="deleteNotes(index)"
        />
      </b-list-group-item>
    </b-list-group>
    <b-button v-t="'pages.physicalObjects.notesnew'" @click="addNotes" />
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "DatingsComponent",
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      content: this.value,
      languages: []
    };
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    },
    changed() {
      this.$emit("change");
    },
    addNotes() {
      this.content.push({ notes: "", lang: { id: "sv-se" } });
      this.$emit("change");
    },
    deleteNotes(index) {
      this.content.splice(index, 1);
      this.$emit("change");
    }
  },
  apollo: {
    languages() {
      return {
        query: gql`
          query languages {
            languages: modernLanguages {
              id: language
              sv
            }
          }
        `,
        result(result) {
          if (result.stale) {
            // result is called again with stale = true
            return;
          }

          this.languages = result.data.languages.sort((a, b) =>
            a.sv.localeCompare(b.sv, "sv")
          );
        }
      };
    }
  }
};
</script>
